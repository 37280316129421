import React from 'react'
import SvgIcon, { SvgIconProps } from '../SvgIcon'

const GiftRibbon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="85" height="88" viewBox="0 0 85 88" {...props}>
      <g clipPath="url(#clip0_26_243)">
        <g filter="url(#filter0_d_26_243)">
          <path
            d="M73.6523 -17L84.9661 -5.68629L-19.6857 98.9655L-30.9995 87.6518L73.6523 -17Z"
            fill="#FEFEFE"
          />
          <path
            d="M73.6523 -17L84.9661 -5.68629L-19.6857 98.9655L-30.9995 87.6518L73.6523 -17Z"
            fill="url(#paint0_linear_26_243)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_26_243"
          x="-36.9995"
          y="-21"
          width="127.966"
          height="127.966"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0 0.0705882 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_26_243"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_26_243"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_26_243"
          x1="-25.3426"
          y1="93.3086"
          x2="79.3092"
          y2="-11.3432"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.251439" stopOpacity="0.1" />
          <stop offset="0.700197" stopColor="white" stopOpacity="0" />
          <stop offset="0.906416" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_26_243">
          <rect width="85" height="88" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default GiftRibbon
